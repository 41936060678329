import React, { useEffect, useState } from 'react';
import { Box } from 'rebass/styled-components';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import { GetContactData } from '../../storage/provider';
import Deindex from '../../components/Deindex';
import Footer from '../../components/Footer';
import ThankYouTitle from '../../components/ThankYouTitle';
import Layout from '../../components/atoms/layout';
import PropsThanksForApply from '../../interface/template';

const ThanksForContact: React.SFC<any> = (props: PropsThanksForApply) => {
  const { pageContext } = props;
  const { pageName } = pageContext;

  return (
    <>
      <Deindex />
      <Box mb={[200, 300]}>
        <Layout>
          <ThankYouTitle
            subtitles={[
              `We have sent you an email to start your admission process. If you don´t find it in your inbox, please check your Promotion and Spam folders.`,
            ]}
            title={`Thanks for Applying to Zomato & Ironhack Scholarship`}
          />
        </Layout>
      </Box>

      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: { regex: "/(menu)|(seo)|(breadcrumbs)|(banner)/" }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(ThanksForContact);
